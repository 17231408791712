import { styled } from '@mui/system'
import { type PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../../../components/Layout'
import { MarriageRings } from '../../../components/MarriageRings'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import { useMarriageRingParams } from '../../../hooks/useMarriageRingParams'

const DESCRIPTION =
    '鑑定書付き安心のハードプラチナ素材で作られた、結婚指輪（マリッジリング）が業界最安値（1万円台から）で購入いただけます。'
export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリング（プラチナ）をお探しなら｜ミスプラチナ"
        description={DESCRIPTION}
        page="marriagering/material/platinum"
    />
)

const PlatinumPage: React.FC<PageProps> = ({ location }) => {
    const title = (
        <H1>
            <Txt size="l">プラチナ</Txt>
            <Txt size="s">素材から結婚指輪を探す</Txt>
        </H1>
    )
    const params = useMarriageRingParams(location.search)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: '素材から探す', path: 'marriagering/material/' },
                { name: 'プラチナ' },
            ]}
        >
            <MarriageRings
                title={title}
                description={DESCRIPTION}
                material
                {...params}
                platinum
            />
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})

export default PlatinumPage
